import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
import {
  Card,
  Form,
  Row,
  Col,
  Space,
  Button,
  InputNumber,
  message,
} from "antd";
import InputFactory from "./InputFactory";
import SearchSelect from "@/components/SearchSelect";
import { formLayout, CurrencyFormat } from "@/utils/enum";
import { useSearchCache } from "@/utils/hook";
import {
  searchFieldsFilterInitialValues,
  searchFieldsFormat,
  rangeLimitDays,
  // priceFormat,
} from "@/utils/format";

/**
 * fields
 * @param {object} fields
 * @param {object} fields.name - field key
 * @param {string} fields.name.type - input type [string, select, switch, checkbox, rangeDate]
 * @param {string} fields.name.label - form label
 * @param {string} fields.name.options - when type === 'select', pass options.
 * @function handleSubmit  - 送出表單
 */
export const SearchFormFactory = ({
  fields = {},
  showExportBtn = false,
  exportLoading = false,
  handleSubmit = () => {},
  handleExport = () => {},
  otherBtn = null,
}) => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const handleReset = async () => {
    await form.resetFields();
    await form.setFieldsValue(initialValues);
    handleResetSearchCache({ pathname });
  };
  const { pathname } = useLocation();
  const {
    handleGetPageSearchCache,
    handleSetSearchCache,
    handleResetSearchCache,
  } = useSearchCache();
  const handleFields = useCallback(() => {
    const formModel = form.getFieldsValue();
    Object.keys(formModel).forEach(i => {
      formModel[i] === undefined && delete formModel[i];
    });
    const params = searchFieldsFormat({ fields, formModel });
    handleSetSearchCache({ pathname, formModel: { ...params } });
    return params;
  }, [fields, form, handleSetSearchCache, pathname]);

  const handleSearchClick = useCallback(() => {
    const params = handleFields();
    handleSubmit(params);
  }, [handleSubmit, handleFields]);

  const handleExportClick = useCallback(() => {
    const params = handleFields();
    if (!params.created__btw && !params.paid_at__btw) {
      message.warning("请选择时间区间");
      return;
    }
    // 下載天數不能超過 90 天
    const dateStr = params.created__btw || params.paid_at__btw;
    const isLimit = rangeLimitDays(90, dateStr);
    isLimit ? handleExport(params) : message.warning("下載量不得超過90天");
  }, [handleExport, handleFields]);

  // const timer = useRef(null);
  const init = async () => {
    const fieldsInitialValues = searchFieldsFilterInitialValues(fields);
    setInitialValues(fieldsInitialValues);
    let searchCache = handleGetPageSearchCache();
    // 處理 searchCache 格式 for InputFactory
    if (Object.keys(searchCache).length) {
      Object.keys(searchCache).forEach(i => {
        if (i.indexOf("__btw") !== -1) {
          searchCache[i] = searchCache[i].split("~").map(j => moment(j));
        }
        if (fields[i]?.type === "searchSelect") {
          const temp = searchCache[i].split(",");
          searchCache[i] = temp.map(item => Number(item));
        }
        // 资金类型格式處理
        if (["type__ne", "type__eq"].includes(i)) {
          searchCache["funding_type"] = `{"${i}":${searchCache[i]}}`;
        }
      });
    }
    await form.setFieldsValue({ ...fieldsInitialValues, ...searchCache });
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [pathname]);

  const valuePropName = type =>
    type === "checkbox" || type === "switch" ? "checked" : "value";
  return (
    <Card size="small">
      <Form form={form} {...formLayout}>
        <Row gutter={24}>
          {Object.keys(fields).map(i => {
            const inputFactoryProps = fields[i];
            const { initialValue, optionLabel, ...rest } = inputFactoryProps;
            if (fields[i].type === "hidden") return null;
            return (
              <Col xs={24} md={12} xl={8} key={i}>
                <Form.Item
                  name={i}
                  label={fields[i].label}
                  valuePropName={valuePropName(fields[i].type)}
                  initialValue={initialValue}
                >
                  {inputFactoryProps.type === "searchSelect" ? (
                    <SearchSelect
                      {...rest}
                      searchKey={inputFactoryProps.searchKey || "name"}
                      val={inputFactoryProps.val || "id"}
                      label={optionLabel}
                    />
                  ) : (
                    <InputFactory
                      {...inputFactoryProps}
                      id={`search_${fields[i].label}`}
                    />
                  )}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
        <Row justify="space-between">
          <Col span={8} className="text-left">
            <Space size="small">
              {showExportBtn && (
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={handleExportClick}
                  loading={exportLoading}
                >
                  汇出
                </Button>
              )}
              {otherBtn}
            </Space>
          </Col>
          <Col span={8} className="text-right">
            <Space size="small">
              <Button onClick={handleReset}>清除</Button>
              <Button type="primary" onClick={handleSearchClick}>
                搜寻
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export const CurrencyHelpTextFormItemFactory = ({
  name = "",
  defaultValKey = "",
  label = "",
  row = {},
  min = 0,
  max = null,
  defaultValue = null,
  ...rest
}) => {
  // 获取当前货币的汇率和单位
  const showRate = CurrencyFormat.find(i => i.key === row?.currency)?.rate || 1; // 默认汇率为 1
  const showCurrency =
    CurrencyFormat.find(i => i.key === row?.currency)?.unit || "¥";

  // 初始化值，单位为元
  const [value, setValue] = useState(
    defaultValue ? defaultValue / showRate : 0,
  );

  // 将后台存储的值（分）转换为元
  const getValueProps = val => {
    return { value: val ? val / showRate : 0 };
  };

  // 将用户输入的值（元）转换为后台存储的值（分）
  const setValueProps = e => {
    const yuanValue = e?.target ? e.target.value : e;
    return Math.round(yuanValue * showRate); // 转换为分并取整
  };

  // 动态提示
  const helpText = useCallback(() => {
    const amount =
      value || row[defaultValKey] / showRate || row[name] / showRate || 0;
    return `数量：${showCurrency} ${amount} 元`;
  }, [defaultValKey, name, row, showCurrency, showRate, value]);

  return (
    <Form.Item
      {...rest}
      name={name}
      label={label}
      validateStatus="warning"
      valuePropName="value"
      getValueProps={getValueProps}
      getValueFromEvent={setValueProps}
      help={helpText()}
    >
      <InputNumber
        min={min}
        max={max}
        value={value} // 保持受控
        onChange={val => setValue(val || 0)} // 同步组件状态
      />
    </Form.Item>
  );
};
